import {Col, Row} from "reactstrap";
import React from "react";
import classnames from "classnames";
import componentStyles from "./customerShort.module.css"
import ArminWittImage from "./ArminWittImage";

export const CustomerShort = () => (
    <Row className={componentStyles.row}>
        <Col md={{size: 6, offset: 3}} className="align-self-center">
            <table className={componentStyles.table}>
                <tbody>
                <tr>
                    <td>
                        <h2 className={componentStyles.citation}>"Endlich keine aufgeblasene Arztsoftware"</h2>
                    </td>
                </tr>
                <tr>
                    <td className={componentStyles.imageData}>
                        <ArminWittImage className={classnames('componentStyles.image', 'shadow')}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className={componentStyles.doctorName}>
                            <span><b>Univ. Prof. Dr. Armin Witt</b></span><br/>
                            <span>Gynäkologe in 1090 Wien</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </Col>
    </Row>
);