import React, { Component } from 'react'
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
  CarouselControl,
} from 'reactstrap'
import OrdinationsManagementImage from './OrdinationsManagement'
import GlobaleSucheImage from './GlobaleSuche'
import FinanzManagementImage from './FinanzManagementImage'
import OfflineFirstImage from './OfflineFirstImage'
import WartelisteImage from './WartelisteImage'
import AutomatischesBackupImage from './AutomatischesBackupImage'
import BefundeRezepteImage from './BefundeRezepteImage'
import SemantischeTextvorlagenImage from './SemantischeTextvorlagenImage'
import VerschluesselungImage from './VerschluesselungImage'
import ChatImage from './ChatImage'
import RealtimeSyncImage from './RealtimeSyncImage'
import "./md-Carousel.css";

const items = [
  {
    key: 'ordinationsManagement',
    header: '',
    text: '',
  },
  {
    key: 'globaleSuche',
    header: '',
    text: '',
  },
  {
    key: 'finanzManagement',
    header: '',
    text: '',
  },
  {
    key: 'warteliste',
    header: '',
    text: '',
  },
  {
    key: 'offlineFirst',
    header: '',
    text: '',
  },
  {
    key: 'automatischesBackup',
    header: '',
    text: '',
  },
  {
    key: 'befundeRezepte',
    header: '',
    text: '',
  },
  {
    key: 'semantischeTextvorlagen',
    header: '',
    text: '',
  },
  {
    key: 'verschluesselung',
    header: '',
    text: '',
  },
  {
    key: 'chat',
    header: '',
    text: '',
  },
  {
    key: 'realtimeSync',
    header: '',
    text: '',
  },
]

class MBCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  renderImage(key) {
    switch (key) {
      case 'ordinationsManagement':
        return <OrdinationsManagementImage />
      case 'globaleSuche':
        return <GlobaleSucheImage />
      case 'finanzManagement':
        return <FinanzManagementImage />
      case 'warteliste':
        return <WartelisteImage />
      case 'offlineFirst':
        return <OfflineFirstImage />
      case 'automatischesBackup':
        return <AutomatischesBackupImage />
      case 'befundeRezepte':
        return <BefundeRezepteImage />
      case 'semantischeTextvorlagen':
        return <SemantischeTextvorlagenImage />
      case 'verschluesselung':
        return <VerschluesselungImage />
      case 'chat':
        return <ChatImage />
      case 'realtimeSync':
        return <RealtimeSyncImage />
      default:
        return <OrdinationsManagementImage />
    }
  }

  render() {
    const { activeIndex } = this.state

    const slides = items.map(item => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.key}
        >
          {this.renderImage(item.key)}
          <CarouselCaption
            className="text-muted"
            captionText={item.text}
            captionHeader={item.header}
          />
        </CarouselItem>
      )
    })

    return (
      <div>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {slides}
          <CarouselControl
            className="carousel-control-left"
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            className="carousel-control-right"
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
      </div>
    )
  }
}

export default MBCarousel
