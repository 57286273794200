import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import MBCarousel from '../components/md-carousel'
import ReactGA from 'react-ga'
import { withPrefix, Link } from 'gatsby'
import workstation from '../images/workstation.png'
import intelligence from '../images/intelligence.png'
import globalSearch from '../images/globalSearch.png'
import encryption from '../images/encryption.png'
import patientenkarteiIcon from '../images/patientenkarteiIcon.png';
import finance from '../images/finance.png'
import befundeRezepteIcon from '../images/befundeRezepteIcon.png'
import flow from '../images/flow.png'
import warteliste from '../images/warteliste.png'
import { Pricing } from '../components/pricing'
import { CustomerShort } from '../components/customerShort'

ReactGA.initialize('UA-47607581-3');
ReactGA.pageview('/');

const firstFeatures = [
  {
    key: 'ordinationsmanagement',
    image: flow,
    title: 'Ordinationsmanagement',
    text:
      'Mit Medoboard haben Sie das Honorar- und Formularwesen mit Versicherungsträgern immer im Blick. Auf einen Klick.',
    more: '/ordinationsmanagement',
  },
  {
    key: 'globalSearch',
    image: globalSearch,
    title: 'Globale Suche',
    text:
      'Medoboard ermöglicht eine globale Suche - in allen Patientendaten und Feldern. Schneller als Sie tippen können.',
    more: '/globale-suche',
  },
  {
    key: 'finanzManagement',
    image: finance,
    title: 'Finanzmanagement',
    text:
      'Medoboard erstellt Honorarnoten voll automatisiert und mit intelligent vorbelegten Inhalten. Mit einem Klick zum Honorar.',
    more: '/finanzmanagement',
  },
];

const secondFeatures = [
  {
    key: 'warteliste',
    image: warteliste,
    title: 'Warteliste',
    text:
      'Mit der Warteliste von Medoboard behalten Sie immer den Überblick über Ihre Patienten. Und Ihr Team. Jederzeit und von überall.',
    more: '/warteliste',
  },
  {
    key: 'offlineFirst',
    image: workstation,
    title: 'Performance inklusive',
    text:
      'Medoboard arbeitet offline-first. Und damit extrem schnell. Vergessen Sie langsames Internet.',
    more: '/offlineFirst',
  },
  {
    key: 'patientenkartei',
    image: patientenkarteiIcon,
    title: 'Ihre Patientenkartei',
    text:
      'Medoboard unterstützt Sie optimal in der Patientenkartei - durch konfigurierbare Textfelder.',
    more: '/patientenkartei/',
  },
];

const thirdFeatures = [
  {
    key: 'befundeRezepte',
    image: befundeRezepteIcon,
    title: 'Befunde und Rezepte',
    text:
      'Medoboard ermöglicht Ihnen die Befund- und Rezeptausstellung in Rekordzeit. Immer mit dem aktuellen Erstattungskodex.',
    more: '/befunde-und-rezepte',
  },
  {
    key: 'semantischeTextvorlagen',
    image: intelligence,
    title: 'Semantische Textvorlagen',
    text:
      'Medoboard bietet absolute Flexibilität bei der täglichen Arbeit: Jedes Textfeld kann mit fachspezifischen Texten vorbelegt werden.',
    more: '/semantische-textvorlagen',
  },
  {
    key: 'secure',
    image: encryption,
    title: 'Verschlüsselung',
    text:
      'Medoboard setzt als Zero-Knowledge Provider stärkste Verschlüsselung ein. Nicht einmal wir selbst können Ihre Daten lesen.',
    more: '',
  },
];

class IndexPage extends Component {
  render() {
    const renderRow = features =>
      features.map(f => {
        return (
          <Col lg="4" key={f.key}>
            <div
              className="shadow p-4 mb-5 bg-white rounded"
              style={{ height: '450px' }}
            >
              <img
                src={f.image}
                className="rounded mx-auto d-block mt-3"
                alt={f.title}
                style={{ height: 120}}
              />
              <h4 className="mt-5">{f.title}</h4>
              <p className="mt-3">{f.text}</p>
              {f.more && (
                <span style={{ position: 'absolute', bottom: 80 }}>
                  <Link to={f.more}>Mehr erfahren</Link>
                </span>
              )}
            </div>
          </Col>
        )
      });

    return (
      <Layout>
        <SEO
          googleTitle="Medoboard - optimiert Ihre Ordination"
          googleDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten und optimiert Ihre Praxis."
          fbUrl="https://medoboard.com"
          fbImage={withPrefix('medoboard-fb.png')}
          fbTitle="Medoboard - optimiert Ihre Ordination"
          fbDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten."
          keywords={[
            `medoboard`,
            `Ordinationssoftware`,
            `intelligent`,
            `einfach`,
            `Patientenakte`,
            `Arztsoftware`,
          ]}
        />
        <MBCarousel />
        <Container>
          <hr className="featurette-divider" />
          <CustomerShort />
          <hr className="featurette-divider" />
          <Row>{renderRow(firstFeatures)}</Row>
          <Row>{renderRow(secondFeatures)}</Row>
          <Row>{renderRow(thirdFeatures)}</Row>
          <hr className="featurette-divider" />
          <Pricing />
        </Container>
      </Layout>
    )
  }
}

export default IndexPage
