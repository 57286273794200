import React from "react"
import {StaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

const ArminWittImage = () => (
    <StaticQuery
        query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "ArminWitt.jpg" }) {
          childImageSharp {
            fixed(width: 200, height: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
        render={data => <Img className="rounded-circle" fixed={data.placeholderImage.childImageSharp.fixed}/>}
    />
)
export default ArminWittImage